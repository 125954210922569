module.exports = [
    'Acquisition of Land for Open Space and Public Recreation Uses',
    'Brownfield Redevelopment',
    'Building Code',
    'Building Code Effectiveness Grading Schedule Score',
    'Capital Improvement plan',
    'Climate Change Adaption ',
    'Coastal Zone Management',
    'Coastal Zone Management Plan',
    'Community Wildfire Protection Plan',
    'Comprehensive/Master Plan',
    'Continuity of Operations Plan',
    'Disaster Recovery Plan',
    'Drought Preparedness Plan',
    'Economic Development Plan',
    'Emergency Operations Plan',
    'Fire Department ISO rating',
    'Flood Insurance Rate Map (FIRM)',
    'Floodplain Ordinance',
    'Local Emergency Operations Plan',
    'Natural Hazard Specific Ordinance',
    'Site Plan Review Requirements',
    'Stormwater Management Program',
    'Water Management Plan',
    'Subdivision Ordinance',
    'Zoning Ordinance',
    'Awareness Initiative/Public Education',
    'Firewise Communities Certification',
    'Natural Disaster/Safety Related Program(s)',
    'Public-Private Partnership Initiatives',
    'StormReady Certification',
    'Education and Outreach-Other',
    'Authority to Levy Taxes for Specific Purposes',
    'Capital Improvement Projects Funding',
    'Community Development Block Grant(s) (CDBG)',
    'Fees',
    'Impact Fees for New Development',
    'Incur Debt Through Private Activities ',
    'Incur Debt Through General Obligation Bonds and/or Special Tax Bonds',
    'Special Purpose Taxes',
    'Storm Water Utility Fee',
    'Technical Panel or Commission',
    'Mitigation Planning Committee',
    'Maintenance Programs to Reduce Risk',
    'Mutual Aid Agreements',
    'Chief Building Official',
    'Floodplain Administrator',
    'Emergency Manager',
    'Community Planner',
    'Civil Engineer',
    'GIS Coordinator',
    'Warning Systems/Services',
    'Hazard Data and Information',
    'Grant Writing',
    'HAZUS Analysis',
    'Planning Commission',
]
module.exports = [
    {value:'1', name:'Federal'},
    {value:'2', name:'State'},
    {value:'3', name:'County'},
    {value:'4', name:'City'},
    {value:'5', name:'Town'},
    {value:'6', name:'Village'},
    {value:'7', name:'Mixed Government'},
    {value:'8', name:'Private'},
    {value:'9', name:'Public School District or BOCES'},
    {value:'10',name:'Road Right of Way'},
    {value:'-999', name:'Unknown'}
]
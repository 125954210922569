const config =  {
	'Historical Hazard Loss' : [
		{
			title: 'Hazards of concern',
			type: 'content',
			
		}
	]
		
}

export default config
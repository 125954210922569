module.exports = {
    71000: 'Industrial Facility',
    71001: 'Chemical Facility',
    71002: 'Manufacturing Facility',
    71004: 'Aircraft Manufacturing Facility',
    71006: 'Armament Manufacturing Facility',
    71008: 'Automotive Mftg Facility',
    71010: 'Durable / Non-Durable Goods',
    71012: 'Explosives Facility',
    71014: 'Fertilizer Facility',
    71016: 'Hazardous Materials Facility',
    71018: 'Hazardous Waste Facility',
    71020: 'Household Products Facility',
    71022: 'Landfill',
    71024: 'Lumber Mill / Saw Mill',
    71026: 'Maintenance Yard',
    71028: 'Manufacturing Warehouse',
    71030: 'Mine',
    71032: 'Mine Waste Disposal Site',
    71034: 'Mine Uranium Facility',
    71036: 'Nuclear Weapons Facility',
    71038: 'Ore Processing Facility',
    71040: 'Paper / Pulp Mill',
    71042: 'Pharmaceutical Plant',
    71044: 'Semiconductor & Microchip Flty',
    71046: 'Shipyard',
    71048: 'Steel Plant',
    71050: 'Superfund Site',
    71052: 'Textile Plant',
    75000: 'Energy Facility',
    75002: 'Energy Distribution Control Flty',
    75004: 'Natural Gas Facility',
    75006: 'Nuclear Fuel Plant',
    75008: 'Nuclear Research Facility',
    75009: 'Nuclear Waste Processing / Strg Flty',
    75010: 'Nuclear Weapons Plant',
    75012: 'Oil / Gas Facility',
    75014: 'Oil / Gas Well of Field',
    75016: 'Oil / Gas Extract. or Injection Well',
    75018: 'Oil / Gas Pumping Station',
    75020: 'Oil / Gas Refinery',
    75022: 'Oil / Gas Processing Plant',
    75024: 'Oil / Gas Storage Flty / Tank Farm',
    75026: 'POL Storage Tank',
    75028: 'Strategic Petroleum Reserve',
    75030: 'Electric Facility',
    75032: 'Hydroelectric Facility',
    75034: 'Nuclear Facility',
    75036: 'Solar Facility',
    75038: 'Substation',
    75039: 'Coal Facility',
    75040: 'Wind Facility',
    75041: 'Waste / Biomass Facility',
    75042: 'Tidal Facility',
    75043: 'Geothermal Facility',
    73000: 'Education Facility',
    73002: 'School',
    73004: 'College / University',
    76000: 'Banking or Finance Facility',
    76004: 'Bank',
    76006: 'Bullion Repository',
    76008: 'Check Clearing Center',
    76010: 'Commodities Exchange',
    76012: 'Federal Reserve Bank / Branch',
    76014: 'Financial Processing Center',
    76016: 'Financial Services Company',
    76018: 'Investment / Brokerage Center',
    76020: 'Insurance and Finance Center',
    76022: 'Stock Exchange',
    76024: 'U.S. Mint / Bur. of Engraving & Pringing',
    83000: 'Government of Military Facility',
    83002: 'Bureau of Land Mgmt Facility',
    83004: 'US Capitol',
    83006: 'State Capitol',
    83008: 'US Supreme Court',
    83010: 'State Supreme Court',
    83011: 'Court House',
    83012: 'Critical Federal Contractor Flty',
    83014: 'Department of Energy Facility',
    83016: 'Department of State Facility',
    83018: 'Dept of Motor Vehicle Facility',
    83020: 'DoD / Military Facility',
    83022: 'Governor Residence',
    83024: 'Intelligence Facility',
    83026: 'Local Government Facility',
    83028: 'NASA Facility',
    83030: 'National Guard Armory / Base',
    83032: 'National Park Service Facility',
    83034: 'State Government Facility',
    83036: 'Tribal Government Facility',
    83038: 'US Forest Service Facility',
    83040: 'Us Government Facility',
    83042: 'White House',
    83043: 'Department of Public Works',
    83044: 'City / Town Hall',
    88000: 'Information/Communication Flty',
    88002: 'Communication Tower',
    88004: 'Data Center',
    88006: 'Internet DNS Location',
    88008: 'Internet Metro Area Exchge / Hub',
    88010: 'Internet Service Provider',
    88012: 'Radio / TV Broadcast Facility',
    88014: 'Satellite Ground Station',
    88016: 'Telephone Facility',
    78000: 'Mail or Shipping Facility',
    78002: 'Air Shipping Hub',
    78004: 'Bulk Mail Center',
    78006: 'Post Office',
    78008: 'Pvte and Express Shipping Flty',
    84000: 'Weather Facility or Structure',
    84002: 'Warning Center',
    84004: 'Weather Data Center',
    84006: 'Weather Forecast Office',
    84008: 'Weather Radar Site',
    70100: 'Agriculture or Livestock Structure',
    70101: 'Agriculture Experimental Station',
    70102: 'Food Industry Facility',
    70103: 'Bakery (Regional)',
    70104: 'Beverage Bottling Plant',
    70106: 'Brewery / Distillery / Winery',
    70108: 'Cannery',
    70110: 'Corral',
    70112: 'Dairy',
    70114: 'Farm / Ranch',
    70116: 'Feedlot',
    70118: 'Food Distribution Center',
    70120: 'Fish Farm / Hatchery',
    70121: 'Fish Ladder',
    70122: 'Grain Elevator',
    70124: 'Grain Mill',
    70126: 'Greenhouse / Nursery',
    70128: 'Livestock Complex',
    70132: 'Meat Processing / Pkg Facility',
    70134: 'Stockyard / Feedlot',
    70136: 'Veternary Hospital / Clinic',
    82000: 'Public Attraction/Landmark Bldg',
    82002: 'Amusement / Water Park',
    82004: 'Arboretem / Botanical Garden',
    82006: 'Auditorium/Theater',
    82008: 'Campground',
    82010: 'Cemetary',
    82011: 'Community / Recreation Center',
    82012: 'Convention Center',
    82014: 'Fair / Exhibition / Rodeo Grounds',
    82016: 'Gold Course',
    82018: 'Historic Site / Point of Interest',
    82020: 'Houe of Worship',
    82022: 'Ice Arena',
    82024: 'Library',
    82026: 'Lighthouse / Light',
    82028: 'Lockout Tower',
    82030: 'Marina',
    82032: 'Museum',
    82034: 'National Symbol / Monument',
    82036: 'Observatory',
    82038: 'Outdoor Theater / Amphitheater',
    82040: 'Picnic Area',
    82042: 'Racetrack / Dragstrip',
    82044: 'Ski Area / Ski Resort',
    82046: 'Sports Arena / Stadium',
    82047: 'Trailhead',
    82048: 'Visitor / Information Center',
    82050: 'Zoo',
    74000: 'Emergency Response / Law Enfmt Flty',
    74001: 'Ambulence Service',
    74002: 'American Red Cross Facility',
    74004: 'Forder Patrol',
    74006: 'Bur.of Alchohol, Tobacco, and Firearms',
    74008: 'Civil Defense',
    74010: 'Coast Guard',
    74012: 'Customs Service',
    74014: 'Department of Justice',
    74016: 'Drug Enforcement Agency',
    74018: 'Federal Bureau of Investigation',
    74020: 'Federal Emergency Mgmt Agency',
    74022: 'Fire Equipment Manufacturer',
    74024: 'Fire Hydrant',
    74026: 'Fire Station / EMS Station',
    74028: 'Fire Training Facility / Academy',
    74030: 'Immigration and Naturalization Service',
    74032: 'Marshal Service',
    74034: 'Law Enforcement',
    74036: 'Prison / Correctional Facility',
    74038: 'Search and Rescue Office',
    74040: 'Secret Service',
    74042: 'Transportation Safety Board',
    74044: 'Office of Emergency Management',
    72000: 'Commercial or Retail Facility',
    72002: 'Corporate Headquarters',
    72004: 'Gas Station',
    72006: 'Grocery Store',
    72008: 'Hotel / Motel',
    72010: 'Shopping Mall / Complex',
    72012: 'Warehouse (Retail / Wholesale)',
    79000: 'Building General',
    79002: 'Mobile Home Park',
    79004: 'Multi-Family Dwelling',
    79006: 'Single-Family Dwelling',
    79008: 'Institutional Residence / Dorm / Barracks',
}
module.exports = [
    {"value": 1, "name": "Division of Homeland Security"}, {"value": 2, "name": "Department Of State"}, {
        "value": 3,
        "name": "Dept of Environ Conservtion"
    }, {"value": 4, "name": "Off of Children & Family Serv"}, {
        "value": 5,
        "name": "Division of State Police"
    }, {"value": 6, "name": "Office of Mental Health"}, {
        "value": 7,
        "name": "Ofc for People w/ Disabilities"
    }, {"value": 8, "name": "Suny Central Admin"}, {"value": 9, "name": "Department of Labor"}, {
        "value": 10,
        "name": "Office of General Services"
    }, {"value": 11, "name": "Department of Health"}, {
        "value": 12,
        "name": "Off of Alchl & Substance Abuse"
    }, {"value": 13, "name": "Department of State"}, {"value": 14, "name": "Unified Court System"}, {
        "value": 15,
        "name": "Department of Transportation"
    }, {"value": 16, "name": "Off Of General Services"}, {
        "value": 17,
        "name": "Div Military & Naval Affairs"
    }, {"value": 18, "name": "State Education Department"}, {
        "value": 19,
        "name": "Off of Parks, Rec & Hstrc Pres"
    }, {"value": 20, "name": "State University of New York"}, {
        "value": 21,
        "name": "Adirondack Park Agency"
    }, {"value": 22, "name": "AGRICULTURE & MARKETS"}, {
        "value": 23,
        "name": "Off of Addict Srvcs & Supports"
    }, {"value": 24, "name": "Dept of Corrctns_Cmmnty Sprvsn"}
]